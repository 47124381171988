import axios from 'axios';
import moment from 'moment';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

axios.interceptors.request.use((request) => {
  store.commit('pushIsLoading');
  return request;
});

axios.interceptors.response.use(
  (response) => {
    store.commit('popIsLoading');
    return response;
  },
  (error) => {
    store.commit('popIsLoading');
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      if (router.currentRoute.path !== '/login') {
        store.commit('resetState');
        router.push('/login');
      }
    }
    return Promise.reject(error);
  },
);

Vue.prototype.$http = axios;
moment.locale('de');
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASEURL;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
