import axios from 'axios';

export default {
  async login({ commit }: any, user: { username: string; password: string }) {
    const response = await axios.post('/login', {
      username: user.username,
      password: user.password,
    });
    console.log(response.data);
    commit('setIsAuthorized', true);
    commit('setUsername', response.data.username);
    commit('setStoreItems', response.data.storeItems);
    commit('setStoreIds', response.data.storeIds);
  },

  async logout({ commit }: any) {
    await axios.get('logout');
    window.location.href = '/login';
    commit('logout');
  },

  async getMyStores({ commit }: any) {
    const stores = await axios.get('stores');
    commit('setMyStores', stores.data);
  },

  async getOrders({ commit }: any) {
    const response = await axios.get('orders');
    commit('setOrders', response.data);
  },

  async getServerOrderCount({ commit }: any) {
    const response = await axios.get('orders/count');
    commit('setServerOrderCount', response.data);
  },

  async getSalesChart({ commit, state }: any) {
    const response = await axios.get('charts/sales', {
      params: {
        startdate: state.dates[0],
        enddate: state.dates[1],
      },
    });
    commit('setSalesChart', response.data);
  },

  async getStoresChart({ commit, state }: any) {
    const response = await axios.get('charts/stores', {
      params: {
        startdate: state.dates[0],
        enddate: state.dates[1],
      },
    });
    console.log(response);
    commit('setStoresChart', response.data);
  },

  async getPaymentTypeChart({ commit, state }: any) {
    const response = await axios.get('charts/paymenttypes', {
      params: {
        startdate: state.dates[0],
        enddate: state.dates[1],
      },
    });
    commit('setPaymentTypeChart', response.data);
  },
};
