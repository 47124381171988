import getDefaultState from './defaultState';

export default {
  logout(state: any) {
    localStorage.clear();
    Object.assign(state, getDefaultState());
  },
  resetState(state: any) {
    Object.assign(state, getDefaultState());
  },
  setUsername(state: any, username: string) {
    state.username = username;
    localStorage.setItem('user', username);
  },
  setStoreItems(state: any, storeItems: []) {
    state.storeItems = storeItems;
    localStorage.setItem('storeItems', JSON.stringify(storeItems));
  },
  setStoreIds(state: any, storeIds: []) {
    state.storeIds = storeIds;
    localStorage.setItem('storeIds', JSON.stringify(storeIds));
  },
  setIsAuthorized(state: any, isAuthorized: boolean) {
    state.isAuthorized = isAuthorized;
  },
  setMyStores(state: any, stores: object[]) {
    state.stores = stores;
  },
  setOrders(state: any, orders: object[]) {
    state.orders = orders;
  },
  setServerOrderCount(state: any, count: number) {
    state.serverOrderCount = count;
  },
  setSalesChart(state: any, sales: object) {
    state.salesChart = sales;
  },
  setStoresChart(state: any, storesChart: object[]) {
    state.storesChart = storesChart;
  },
  deleteSalesChart(state: any) {
    state.salesChart = {};
  },
  deleteStoresChart(state: any) {
    state.storesChart = [];
  },
  setPaymentTypeChart(state: any, chart: object) {
    state.paymentTypeChart = chart;
  },
  deletePaymentTypeChart(state: any) {
    state.paymentTypeChart = {};
  },
  toggleDrawer(state: any) {
    state.drawer = !state.drawer;
  },
  setDrawer(state: any, toggle: any) {
    state.drawer = toggle;
  },
  setSelectedStores(state: any, selected: any) {
    state.selectedStores = selected;
    localStorage.setItem('selectedStores', JSON.stringify(selected));
  },
  setSelectedInterfaces(state: any, selected: any) {
    state.selectedInterfaces = selected;
    localStorage.setItem('selectedInterfaces', JSON.stringify(selected));
  },
  setDates(state: any, dates: any) {
    state.dates = dates;
    localStorage.setItem('dateRange', JSON.stringify(dates));
  },
  setDateIndex(state: any, dateIndex: any) {
    state.dateIndex = dateIndex;
    localStorage.setItem('dateIndex', dateIndex);
  },
  setLastVersionCheck(state: any, timestamp: number) {
    state.lastVersionCheck = timestamp;
    localStorage.setItem('lastVersionCheck', timestamp.toString());
  },
  pushIsLoading(state: any) {
    state.isLoading.push(true);
  },
  popIsLoading(state: any) {
    state.isLoading.pop();
  },
};
