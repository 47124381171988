import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { de } from 'vuetify/src/locale';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  theme: {
    themes: {
      light: {
        background: '#e9e9e9',
        primary: colors.red.darken2,
        accent: colors.red.accent1,
        default: colors.blue.darken3,
      },
    },
  },
});
