// prettier-ignore
function getDefaultState() {
  return {
  isAuthorized: false,
  storeIds: localStorage.getItem('storeIds') ? JSON.parse(localStorage.getItem('storeIds') as string) || [] : [],
  stores: [],
  orders: [],
  serverOrderCount: 0,
  selectedStores: JSON.parse(localStorage.getItem('selectedStores') as string) || [],
  selectedInterfaces: JSON.parse(localStorage.getItem('selectedInterfaces') as string) || ['GastroBlitz Shop', 'Lieferando'],
  salesChart: {},
  paymentTypeChart: {},
  storesChart: [],
  username: localStorage.getItem('user') || '',
  storeItems: localStorage.getItem('storeItems') && Boolean(!localStorage.getItem('storeItems')) ? JSON.parse(localStorage.getItem('storeItems') as string) || [] : [],
  drawer: null,
  dates: JSON.parse(localStorage.getItem('dateRange') as string) || [],
  dateIndex: Number(localStorage.getItem('dateIndex') as string) || 0,
  lastVersionCheck: Number(localStorage.getItem('lastVersionCheck')) || 0,
  isLoading: [],
  }
}

export default getDefaultState;
