import axios from 'axios';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';

const Login = () => import('../views/Login.vue');
const Dashboard = () => import('../views/Dashboard.vue');
const PaymentTypes = () => import('../views/dashboard/PaymentTypes.vue');
const Orders = () => import('../views/dashboard/Orders.vue');
const Sales = () => import('../views/dashboard/Sales.vue');
const Umsatz = () => import('../views/dashboard/Umsatz.vue');
const List = () => import('../views/dashboard/List.vue');
const OrderInfo = () => import('../views/dashboard/OrderInfo.vue');

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        redirect: 'sales',
      },
      {
        path: 'orders',
        name: 'Bestellungen',
        component: Orders,
      },
      {
        path: 'paymenttypes',
        name: 'Bezahlarten',
        component: PaymentTypes,
      },
      {
        path: 'sales',
        name: 'Umsatz',
        component: Sales,
      },
      {
        path: 'umsatz',
        name: 'Umsatz pro Store',
        component: Umsatz,
      },
      {
        path: 'list',
        name: 'List',
        component: List,
      },
      {
        path: 'order/:id',
        name: 'OrderInfo',
        component: OrderInfo,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path === from.path) return;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if session is valid
    // if not, redirect to login page.

    if (store.getters.isAuthorized) {
      next();
    } else {
      axios
        .get('validatesession', {
          timeout: 4000,
        })
        .then((response) => {
          console.log(response.data.storeItems);
          store.commit('setIsAuthorized', true);
          store.commit('setUsername', response.data.username ?? '');
          store.commit('setStoreItems', response.data.storeItems ?? []);
          store.commit('setStoreIds', response.data.storeIds ?? []);
          next();
        })
        .catch(() => {
          next({
            path: '/login',
            query: { redirect: to.fullPath },
          });
        });
    }
  } else {
    // this route does not required auth
    next();
  }
});

router.afterEach((to) => {
  const dynamicTitle = to.name !== undefined ? `${to.name} - ` : '';
  document.title = dynamicTitle + process.env.VUE_APP_TITLE;
});

router.afterEach(async () => {
  const lastVersionCheck = Date.now() - store.state.lastVersionCheck;
  if (lastVersionCheck > 60000) {
    // Last check was > 1m ago
    // Fetch new version relative to browser baseURL
    const upstreamVersion = await axios.get('/version', { baseURL: '', responseType: 'text' });
    store.commit('setLastVersionCheck', Date.now());
    if (upstreamVersion.status === 200 && upstreamVersion.data !== process.env.VUE_APP_VERSION) {
      window.location.reload();
    }
  }
});

export default router;
